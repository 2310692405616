/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import BootstrapLoader from "./bootstrap-loader";
import { useIntl } from "gatsby-plugin-intl"
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import "./layout.css"

const Layout = ({ children }) => {
  const { locale } = useIntl();
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTCHA_KEY}
      language={locale}
    >
      <BootstrapLoader />
      <main className="container-fluid main">
        { children }
      </main>
    </GoogleReCaptchaProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
